<template>
  <div>
    <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" url="userLoginLog" @clear="clearDateRange">
      <template slot="adSearch">
        <vm-search :label="$l('maintWorkOrder.filter', '关键字检索')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
        <vm-search :label="$l('userLoginLog.loginType', '登录类型')">
          <el-select v-model.trim="search.loginType" :placeholder="$t('common.pleaseSelect')" style="width: 100%"
            clearable>
            <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </vm-search>
        <vm-search :label="$l('userLoginLog.loginTime', '登录时间')">
          <el-date-picker v-model="dateRange" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange"
            :unlink-panels="true" :range-separator="$t('common.to')" :start-placeholder="$t('common.startDate')"
            :end-placeholder="$t('common.endDate')" :default-time="['00:00:00', '23:59:59']" @change="handleDateChange">
          </el-date-picker>
        </vm-search>
      </template>
      <el-table-column prop="userName" :label="$l('userLoginLog.userName', '用户名')"></el-table-column>
      <el-table-column prop="name" :label="$l('userLoginLog.name', '员工姓名')"></el-table-column>
      <el-table-column prop="loginType" :label="$l('userLoginLog.loginType', '登录类型')" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.loginType === 'web'">
            {{ $l("userLoginLog.web", "网页") }}
          </span>
          <span v-if="scope.row.loginType === 'app'">
            {{ $l("userLoginLog.app", "APP") }}
          </span>
          <span v-if="scope.row.loginType === 'wx'">
            {{ $l("userLoginLog.wx", "微信小程序") }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="loginIp" :label="$l('userLoginLog.loginIp', '登录IP')" align="center"></el-table-column>
      <el-table-column prop="loginTime" :label="$l('userLoginLog.loginTime', '登录时间')" width="140"
        align="center"></el-table-column>
    </vm-table>
  </div>
</template>
<script>
const moduleName = "userLoginLog";
export default {
  userName: "",
  data() {
    return {
      importLoading: false,
      loading: true,
      dateRange: [],
      search: {
        filter: "",
        startTime: "",
        endTime: "",
        loginType: "",
      },
      typeOptions: [
        { value: "web", label: this.$l("newMsgSend.msgPush", "网页") },
        { value: "wx", label: this.$l("newMsgSend.sms", "微信小程序") },
        { value: "app", label: this.$l("newMsgSend.email", "APP") },
      ],
    };
  },
  mounted() {
    this.getList(1);
  },
  methods: {
    getList(pageNum) {
      this.loading = false;
      this.$refs.vmTable.getList(pageNum);
    },
    handleDateChange(dateRange) {
      if (dateRange != null) {
        this.search.startTime = dateRange[0];
        this.search.endTime = dateRange[1];
      } else {
        this.search.startTime = "";
        this.search.endTime = "";
      }
    },
    clearDateRange() {
      this.dateRange = [];
    },
  },
};
</script>